const bootstrap = require('bootstrap/dist/js/bootstrap.bundle');
const cookieConsent = new bootstrap.Offcanvas('#cookie-consent');

if (document.cookie.indexOf('hideCookieAlert=1') !== -1) {
    cookieConsent.hide();
} else {
    cookieConsent.show();
}

document.getElementById('cookie-consent').addEventListener('hidden.bs.offcanvas', event => {
    const date = new Date();
    const days = 365;
    date.setTime(date.getTime() + (days * 86400000)); // 24 * 60 * 60 * 1000
    document.cookie = 'hideCookieAlert=1; expires=' + date.toGMTString() + '; path=/';
})